import React, { useState, useReact } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import moment from "moment/moment"
import styled from 'styled-components'
import { HalfSpacedHr } from '../components/spaced-hr'
import FluidImage from '../components/fluid-image'
import FullWidthSection from '../components/full-width-section'

import featuredImage from '../images/westbankdiner.jpg'

import facebook from '../images/saba-facebook.png'




const PageTitleSection = styled.div`
  background: #0094d9;
  padding: 20px 24px 10px;
  margin: 0 -25px -17px;
  @media (max-width: 767px) {
    padding: 20px 24px 10px;
    display: flex;
    background: #0094d9;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    height: 100%;
    margin: 15px -12px 0;
  }
`

const StyledSection = styled.div`
  margin: 0 -25px 15px -25px;
  border-radius: 3px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin: -17px -12px 30px -12px;
  }
`
const SI = styled.input`
font-size: 1rem;
padding: 1px 10px;
margin: 0 .25rem;
`
const PageSection = styled.div`
  padding: 1rem 0;
  // background: #efefef;
  margin: 0 auto;
  max-width: 55rem;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

const Section1 = styled.div`
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
`

const TR = styled.span`
  background: #fff;
    padding: 3px 8px;
    color: #0094d9;
    font-size: 1rem;
    margin: 1rem 0rem;
    b {
    color: #0094d9;
    font-size: 1.2rem;
    }
`

const HR = styled.hr`
margin: 1.5rem 0;
    border-top: 3px solid #fff;
`
const Row1 = styled.div`
  max-width: 80rem;
  width: 100%;
  margin: 0rem auto;
  padding: 1.5rem 24px 0 24px;

  @media (max-width: 768px) {
    margin: 0rem auto 3rem;
    padding: 0;
  }
`
const P = styled.p`
  color: #10435e !important;
  font-size: 1.35rem;
  margin-bottom: 1.5rem;
`
const H1 = styled.h1`
  margin: 0;
  color: #fff;
`

const Row = ({ children }) => <div className="row">{children}</div>

const Covid19Page = props => {
  const { data } = props
  const { store } = props.data
  const allResources = data.allResources.edges

  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    const query = event.target.value
    console.log(query)
    const { data } = props
    console.log(data)
    // this is how we get all of our posts
    const resources = data.allResources.edges || []
    console.log(resources)
    // return all filtered posts
    const filteredData = resources.filter(resource => {
      // destructure data from post frontmatter
      const {
        Description,
        Name,
        URL,
        Level,
        html,
        id,
        slug,
      } = resource.node

      if (Description === null)
      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string
        Name.toLowerCase().includes(query.toLowerCase()) ||
        Level.toLowerCase().includes(query.toLowerCase()) ||
        URL.toLowerCase().includes(query.toLowerCase()) ||
        html.toLowerCase().includes(query.toLowerCase()) ||
        id.toLowerCase().includes(query.toLowerCase()) ||
        slug.toLowerCase().includes(query.toLowerCase())
      )
      else
        return (
          Name.toLowerCase().includes(query.toLowerCase()) ||
          URL.toLowerCase().includes(query.toLowerCase()) ||
          Level.toLowerCase().includes(query.toLowerCase()) ||
          Description.toLowerCase().includes(query.toLowerCase()) ||
          id.toLowerCase().includes(query.toLowerCase()) ||
          slug.toLowerCase().includes(query.toLowerCase())
        )
    })
    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const resources = hasSearchResults ? filteredData : allResources
  const totalCount = hasSearchResults
    ? filteredData.length
    : data.allResources.totalCount
  console.log(filteredData)
  console.log(filteredData.length)
  return (
    <Layout>
      <SEO title="Home" />
      <Row1>

        <PageTitleSection>
          <H1>COVID-19 Resources</H1>
          <HalfSpacedHr />
        </PageTitleSection>

        <FullWidthSection fluidImage={store.childImageSharp.fluid} />

        <PageSection>
          {/*<h1> {title} </h1>*/}

      <div className="content-container">
        <div className="topjobsArea">
          <p>
            {/*Search:{" "}*/}
            <SI
              type="text"
              aria-label="Search"
              placeholder="Search resources..."
              onChange={handleInputChange}
            />&nbsp;&nbsp;
            <TR>Total: <b>{totalCount}</b></TR>

          </p>
          <HR />
          {/*<p>*/}
          {/*  Sort by:{" "}*/}
          {/*  <Link*/}
          {/*    to={`/posted`}*/}
          {/*    style={{*/}
          {/*      background: "#fff",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Posted Date*/}
          {/*  </Link>{" "}*/}
          {/*  | <Link to={`/closing`}>Closing Date</Link>*/}
          {/*</p>*/}
        </div>
        {/*<Row>*/}
          <div className="jobsWrapper">
            {resources.map(({ node }) => {
              const {
                Description,
                Name,
                URL,
                html,
                Level,
                id,
                slug,
              } = node

              return (
                <div key={id}>
                  <P className="postingTitle">
                  <Link to={`/resources/${slug}`}>
                    {Name}
                  </Link>&nbsp;
                   {Description}
                  </P>
                  {/*<p>*/}
                  {/*  {html}*/}
                  {/*</p>*/}
                  {/*<a href={`/resources/${slug}`} className="viewJobLink">*/}
                  {/*  View Details*/}
                  {/*</a>{" "}*/}
                </div>
              )
            })}
          </div>
        {/*</Row>*/}
      </div>
  {/*<div dangerouslySetInnerHTML={{ __html: html }} />*/}
</PageSection>
</Row1>
</Layout>
  )
}

export default Covid19Page

export const pageQuery = graphql`
  query {
    allResources(sort: {fields: Name, order: ASC}) {
    edges {
      node {
        Description
        Name
        URL
        html
        id
        slug
        Level
      }
    }
    totalCount
  }
 store: file(relativePath: { eq: "saba-we have.jpg" }) {
      ...fluidLargeImage
    }
  }
`